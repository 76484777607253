export function getNewTrademarkPayload(application) {
  if (application.trademarkType === 1) {
    return getWordPayload(application);
  } else if (application.trademarkType === 2) {
    return getFigurativePayload(application);
  } else {
    window.alert("ERROR: trademarkType not valid");
    throw new Error("ERROR: trademarkType not valid");
  }
}

function getWordPayload(application) {
  const payload = {
    agent: {
      identifier: "GAX6677077781",
    }, 
    owners: [{ identifier: application.contactIdentifier }],
    type: "STANDARD",
    kinds: ["WORD"],
    word: {
      wordPhrase: application.trademarkText,
    },
    applicationGoodsAndServices: {
      pickListIndicator: true,
      goodsAndServices: JSON.parse(application.selections),
    },
  };

  return payload;
}

function getFigurativePayload(application) {
  const payload = {
    agent: {
      identifier: "GAX6677077781",
    }, 
    owners: [{ identifier: application.contactIdentifier }],
    type: "STANDARD",
    kinds: ["FIGURATIVE"],
    representations: [
      {
        identifier: application.imageIdentifier,
        sequencePosition: 1,
      },
    ],
    applicationGoodsAndServices: {
      pickListIndicator: true,
      goodsAndServices: JSON.parse(application.selections),
    },
  };

  return payload;
}

// const trademarkCreateResponse = {
//   identifier: "ATSU-2410511748",
//   tradeMark: {
//     auApplicationNumber: "2244051",
//   },
//   fee: {
//     feePayableTotalAmount: "500.00",
//     basicFeeAmount: "500.00",
//     feeComponents: [
//       {
//         feeDescription:
//           "Filing an Electronic Application for TM - Pick List - 2 Classes",
//         feeCalculatedAmount: "500.00",
//       },
//     ],
//   },
// };