
export function getNewContactPayload(application) {
  if (application.ownerType === 1) {
    return companyContactPayload(application);
  } else if (application.ownerType === 2) {
    return personContactPayload(application);
  } else {
    window.alert("ERROR: INCORRECT OWNER TYPE");
    throw new Error("ERROR: INCORRECT OWNER TYPE");
  }
}

function companyContactPayload(application){
  const payload = {
    displayName: application.ownerName,
    acn: application.abnAcn,
    physicalAddress: {
      addressLine1: application.addressLine1,
      addressLine2: application.addressLine2 || undefined,
      addressLine3: application.addressLine3 || undefined,
      cityOrTownName: application.suburb,
      stateOrTerritoryOrCounty: application.state,
      countryCode: application.country,
      postalOrZipCode: application.postcode || undefined,
    },
    // electronicAddress: {
    //   phoneNumber: application.phone,
    //   emailAddress: application.email,
    // },
    preferences: {
      preferredContact: "EMAIL_ADDRESS",
    },
  };
  return payload;
}

function personContactPayload (application){
  const payload = {
    firstName: application.firstName,
    familyName: application.lastName,
    physicalAddress: {
      addressLine1: application.addressLine1,
      addressLine2: application.addressLine2 || undefined,
      addressLine3: application.addressLine3 || undefined,
      cityOrTownName: application.suburb,
      stateOrTerritoryOrCounty: application.state,
      countryCode: application.country,
      postalOrZipCode: application.postcode || undefined,
    },
    // electronicAddress: {
    //   phoneNumber: application.phone,
    //   emailAddress: application.email,
    // },
    preferences: {
      preferredContact: "EMAIL_ADDRESS",
    },
  };
  return payload;
}