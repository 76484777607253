import React from "react";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import { Link } from "react-router-dom";
import post1_740 from "../images/blog/post1_740.jpg";
import post2_740 from "../images/blog/post2_740.jpg";
import post3_740 from "../images/blog/post3_740.png";
import post4_740 from "../images/blog/post4_740.jpg";
import post5_740 from "../images/blog/post5_740.png";
import post6_740 from "../images/blog/post6_740.png";

export default function Blog() {
  return (
    <div id="page" className="site">
      <AppHeader />
      <div id="content" className="site-content">
        <section className="section-blog">
          <div className="container">
            <div className="blogs-feature">
              <Link
                to="/may-the-fourth-be-with-you/"
                id="post-0"
                className="feature post-260390 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <div className="feature-content">
                  <h4>May the fourth be with you!</h4>
                </div>
              </Link>
            </div>

            <div className="flex-title">
              <h2 className="section-title">Latest News</h2>
            </div>

            <div className="blogs-grid">
              <Link
                to="/trademark-filings-in-australia-are-up/"
                className="post-260394 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <img
                  width="740"
                  height="480"
                  src={post1_740}
                  className="attachment-blog-thumb size-blog-thumb wp-post-image"
                  alt=""
                  decoding="async"
                  fetchpriority="high"
                />{" "}
                <h4>Trademark filings in Australia are up!</h4>
                <p className="date">April 27, 2022</p>
              </Link>
              <Link
                to="/au-domain-names/"
                className="post-260373 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <img
                  width="740"
                  height="480"
                  src={post2_740}
                  className="attachment-blog-thumb size-blog-thumb wp-post-image"
                  alt=""
                  decoding="async"
                />{" "}
                <h4>.au domain names</h4>
                <p className="date">March 15, 2022</p>
              </Link>
              <Link
                to="/international-womens-day-2022/"
                className="post-260370 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <img
                  width="740"
                  height="480"
                  src={post3_740}
                  className="attachment-blog-thumb size-blog-thumb wp-post-image"
                  alt=""
                  decoding="async"
                />{" "}
                <h4>International Women's Day 2022</h4>
                <p className="date">March 8, 2022</p>
              </Link>
              <Link
                to="/phasing-out-of-the-australian-official-journal-of-trade-marks/"
                className="post-260364 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <img
                  width="740"
                  height="480"
                  src={post4_740}
                  className="attachment-blog-thumb size-blog-thumb wp-post-image"
                  alt=""
                  decoding="async"
                />{" "}
                <h4>
                  Phasing out of the Australian Official Journal of Trade Marks
                </h4>
                <p className="date">December 3, 2021</p>
              </Link>
              <Link
                to="/malaysia-joins-the-madrid-protocol-for-international-trademarks/"
                className="post-260367 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <img
                  width="740"
                  height="480"
                  src={post5_740}
                  className="attachment-blog-thumb size-blog-thumb wp-post-image"
                  alt=""
                  decoding="async"
                  sizes="(max-width: 740px) 100vw, 740px"
                />{" "}
                <h4>
                  Malaysia joins the Madrid Protocol for International
                  Trademarks
                </h4>
                <p className="date">January 1, 2020</p>
              </Link>
              <Link
                to="/canada-joins-the-madrid-protocol-for-international-trademarks/"
                className="post-260377 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <img
                  width="740"
                  height="480"
                  src={post6_740}
                  className="attachment-blog-thumb size-blog-thumb wp-post-image"
                  alt=""
                  decoding="async"
                />{" "}
                <h4>
                  Canada joins the Madrid Protocol for International Trademarks
                </h4>
                <p className="date">June 17, 2019</p>
              </Link>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
