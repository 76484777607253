// import { useRef, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React from "react";
import Home from "./routes/Home";
import Admin from "./routes/Admin";
import ErrorPage from "./routes/ErrorPage";
import About from "./routes/About";
import Faq from "./routes/Faq";
import Contact from "./routes/Contact";
import TermsOfService2 from "./routes/TermsOfService2";
import Privacy2 from "./routes/Privacy2";
import Application from "./Application";
import OrderConfirmed from "./routes/OrderConfirmed";
import Blog from "./routes/Blog";
import Post0 from "./routes/blogs/Post0";
import Post1 from "./routes/blogs/Post1";
import Post2 from "./routes/blogs/Post2";
import Post3 from "./routes/blogs/Post3";
import Post4 from "./routes/blogs/Post4";
import Post5 from "./routes/blogs/Post5";
import Post6 from "./routes/blogs/Post6";
import Login from "./routes/Login";
import AddressBook from "./routes/AddressBook";
import ScrollToTop from "./components/ScrollToTop";
import Pricing from "./routes/Pricing";
import "./App.css"

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ScrollToTop>
        <Home />
      </ScrollToTop>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "login",
    element: (
      <ScrollToTop>
        <Login />
      </ScrollToTop>
    ),
  },
  {
    path: "admin",
    element: (
      <ScrollToTop>
        <Admin />
      </ScrollToTop>
    ),
  },
  {
    path: "address-book",
    element: (
      <ScrollToTop>
        <AddressBook />
      </ScrollToTop>
    ),
  },
  {
    path: "faq",
    element: (
      <ScrollToTop>
        <Faq />
      </ScrollToTop>
    ),
  },
  {
    path: "about",
    element: (
      <ScrollToTop>
        <About />
      </ScrollToTop>
    ),
  },
  {
    path: "pricing",
    element: (
      <ScrollToTop>
        <Pricing />
      </ScrollToTop>
    ),
  },
  {
    path: "blog",
    element: (
      <ScrollToTop>
        <Blog />
      </ScrollToTop>
    ),
  },
  {
    path: "may-the-fourth-be-with-you",
    element: (
      <ScrollToTop>
        <Post0 />
      </ScrollToTop>
    ),
  },
  {
    path: "trademark-filings-in-australia-are-up",
    element: (
      <ScrollToTop>
        <Post1 />
      </ScrollToTop>
    ),
  },
  {
    path: "au-domain-names",
    element: (
      <ScrollToTop>
        <Post2 />
      </ScrollToTop>
    ),
  },
  {
    path: "international-womens-day-2022",
    element: (
      <ScrollToTop>
        <Post3 />
      </ScrollToTop>
    ),
  },
  {
    path: "phasing-out-of-the-australian-official-journal-of-trade-marks",
    element: (
      <ScrollToTop>
        <Post4 />
      </ScrollToTop>
    ),
  },
  {
    path: "malaysia-joins-the-madrid-protocol-for-international-trademarks",
    element: (
      <ScrollToTop>
        <Post5 />
      </ScrollToTop>
    ),
  },
  {
    path: "canada-joins-the-madrid-protocol-for-international-trademarks",
    element: (
      <ScrollToTop>
        <Post6 />
      </ScrollToTop>
    ),
  },
  {
    path: "contact-us",
    element: (
      <ScrollToTop>
        <Contact />
      </ScrollToTop>
    ),
  },
  {
    path: "trademarkable/trademark",
    element: (
      <ScrollToTop>
        <Application />
      </ScrollToTop>
    ),
  },
  {
    path: "terms-of-service",
    element: (
      <ScrollToTop>
        <TermsOfService2 />
      </ScrollToTop>
    ),
  },
  {
    path: "privacy-policy",
    element: (
      <ScrollToTop>
        <Privacy2 />
      </ScrollToTop>
    ),
  },
  {
    path: "order/orderConfirmed",
    element: (
      <ScrollToTop>
        <OrderConfirmed />
      </ScrollToTop>
    ),
  },
]);

function App() {

  return <RouterProvider router={router} />;
}

export default App;
