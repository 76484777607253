import React from "react";
import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/AppFooter";
export default function Post6() {
  return (
    <div id="page" className="site">
      <AppHeader />
      <div id="content" className="site-content">
        <section className="section-blog">
          <div className="container">
            <div
              id="post6"
              className="blog-post_feature"
            ></div>
          </div>
          <div className="container container-md">
            <div className="page-content">
              <h1>
                Canada joins the Madrid Protocol for International Trademarks
              </h1>
              <p>
                From 17 June 2019, Canada has officially joined the Madrid
                Protocol. This will allow business owners to protect their
                trademarks in Canada via the International Registration system.
              </p>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
