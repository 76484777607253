import React from "react";
// import { useLocation } from "react-router";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";

function OrderConfirmed() {
  // const {
  //   state: { response },
  // } = useLocation();
  // console.log(useLocation());
  return (
    <div id="page" className="site">
      <AppHeader />
      <div
        id="content"
        className="site-content"
        style={{
          padding: "120px 24px",
          width: "75%",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <CheckCircleOutlinedIcon
          color="success"
          sx={{ fontSize: "100px", fill: "#2cd05b" }}
        />
        <br />
        <br />
        <h2>Your payment has been successful!</h2>
        <br />
        <p style={{ color: "#7c848f" }}>
          We will be in touch soon once your trademark application has been
          filed. Thank you for using our services.
        </p>
        {/* <pre>{JSON.stringify(response, null, 2)}</pre> */}
      </div>
      <AppFooter />
    </div>
  );
}

export default OrderConfirmed;
