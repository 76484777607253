import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import countryList from "../json/countriesProd";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function AutoCompleteCountry({ country }) {
  const list = countryList.map((c) => ({ ...c, label: c.countryName }));
  const defaultValue = list.find((item) => item.countryCode === country) || list[13];
  return (
    <Autocomplete
      id="country-select"
      size="small"
      defaultValue={defaultValue}
      autoHighlight
      // freeSolo ={true}
      isOptionEqualToValue={(option, value) =>
        option.countryCode === value.countryCode
      }
      options={list}
      getOptionLabel={(option) => option.countryCode}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.label} {option.countryCode}
        </Box>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            name="country"
            label="Country"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        );
      }}
    />
  );
}

// import React from "react";
// import Autocomplete from '@mui/material/Autocomplete';
// import countryList from "../json/countries.json"
// import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";

// export default function AutoCompleteCountry({defaultValue = {}}) {
//   const [inputValue, setInputValue] = React.useState('');

//   return (
//     <Autocomplete
//       size="small"
//       id="country"
//       defaultValue={defaultValue.country}
//       // onChange={(_event, newValue) => {
//       //   handleCountryChange(newValue);
//       // }}
//       // inputValue={inputValue}
//       // onInputChange={(_event, newInputValue) => {
//       //   setInputValue(newInputValue);
//       // }}
//       name="country"
//       options={countryList}
//       autoHighlight
//       getOptionLabel={(option) => option.name}
//       renderOption={(props, option) => (
//         <Box component="li" {...props}>
//           {option.name}
//         </Box>
//       )}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           name="country"
//           label="Country"
//           inputProps={{
//             ...params.inputProps,
//             autoComplete: "new-password", // disable autocomplete and autofill
//           }}
//         />
//       )}
//     />
//   );
// }
