import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import tradeMarkable from "../images/hero-illustration.svg";
import { useState, useRef } from "react";
import { sendEmail } from "../api/services";

export default function Contact() {
  const [messageText, setMessageText] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = document.querySelector("form");
    const formData = new FormData(form);
    const { name, email, message } = Object.fromEntries(formData);
    const payload = {
      senderName: name,
      senderEmail: email,
      senderMessage: message,
    };
    try {
      setMessageText("");
      setIsSending(true);
      const response = await sendEmail(payload);
      if (response.status === 200) {
        setMessageText("Thanks for contacting us! We will be in touch with you shortly.");
        setIsSending(false);
        setMessageSuccess(true);
      } else {
        throw new Error("An unknown error occured.");
      }
    } catch (ex) {
      setMessageText(ex.message);
      setIsSending(false);
    }
  };

  return (
    <div id="page" className="site">
      <AppHeader />
      <div id="content" className="site-content">
        <section className="hero hero-page hero-page_contact">
          <div className="container">
            <div className="hero-content">
              <h1>Let's get in touch</h1>
              <h3 className="hero-sub">Don't be shy :{")"}</h3>
              {!messageSuccess ? (
                <div className="hero-contact">
                  <div
                    className="wpforms-container wpforms-container-full"
                    id="wpforms-83"
                  >
                    <form
                      ref={formRef}
                      onSubmit={handleSubmit}
                      id="wpforms-form-83"
                      className="wpforms-validate wpforms-form"
                      autoComplete="off"
                    >
                      <div className="wpforms-field-container">
                        <div
                          id="wpforms-83-field_1-container"
                          className="wpforms-field wpforms-field-text"
                        >
                          <label
                            className="wpforms-field-label"
                            htmlFor="wpforms-83-field_1"
                          >
                            Full Name{" "}
                            <span className="wpforms-required-label">*</span>
                          </label>
                          <input
                            type="text"
                            id="name"
                            className="wpforms-field-large wpforms-field-required"
                            name="name"
                            placeholder="Your full name"
                            required
                          />
                        </div>

                        <div
                          id="wpforms-83-field_2-container"
                          className="wpforms-field wpforms-field-email"
                        >
                          <label
                            className="wpforms-field-label"
                            htmlFor="wpforms-83-field_2"
                          >
                            Email Address{" "}
                            <span className="wpforms-required-label">*</span>
                          </label>
                          <input
                            type="email"
                            id="email"
                            className="wpforms-field-large wpforms-field-required"
                            name="email"
                            placeholder="Your email address"
                          />
                        </div>

                        <div
                          id="wpforms-83-field_3-container"
                          className="wpforms-field wpforms-field-textarea"
                        >
                          <label
                            className="wpforms-field-label"
                            htmlFor="wpforms-83-field_3"
                          >
                            Message
                          </label>
                          <textarea
                            id="message"
                            className="wpforms-field-large"
                            name="message"
                            placeholder="Your trademark enquiry"
                            required
                          ></textarea>
                        </div>
                        {/*  */}
                        <div className="wpforms-submit-container">
                          <button
                            disabled={isSending}
                            type="submit"
                            id="wpforms-submit-83"
                            className="wpforms-submit"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>{messageText}</div>
                </div>
              ) : (
                <div className="success-message">{messageText}</div>
              )}
            </div>
            <div className="hero-img page-content">
              <img src={tradeMarkable} alt="Trademarkable" />
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
