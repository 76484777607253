import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { loginUser } from "../api/services";
import { useNavigate } from "react-router-dom";
import AppHeader from "../components/AppHeader";
import LinearProgress from '@mui/material/LinearProgress';

export default function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [userInput, setUserInput] = useState({
    username: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    const response = await loginUser(userInput);
    setIsLoading(false)
    if (response?.data) {
      localStorage.setItem("token", response.data.result.token);
      navigate("/admin");
    } else if (response?.response?.data) {
      setError(response.response.data.errorMessages[0]);
    } else {
      setError("A server error occured");
    }
  };

  const handleUserInput = (e) => {
    const obj = { [e.target.name]: e.target.value };
    setUserInput((prev) => ({ ...prev, ...obj }));
  };

  return (
    <div id="page" className="site">
      <AppHeader />
      <div className="container text-center">
        <form
          id="formLogin"
          method="post"
          onSubmit={handleSubmit}
          style={{
            width: "640px",
            margin: "auto",
            textAlign: "center",
            padding: "24px",
          }}
        >
          <h2>Login</h2>
          <br />
          <br />
          <div>
            <TextField
              name="username"
              placeholder="Username"
              fullWidth
              required
              onChange={handleUserInput}
            />
            <br />
            <br />
            <TextField
              type="password"
              name="password"
              placeholder="Enter Password"
              fullWidth
              required
              onChange={handleUserInput}
            />
          </div>
          <br />
          {isLoading && <p>Loading... <LinearProgress /></p>}
          {error && <p style={{ color: "red" }}>{error}</p>}
          <Button variant="contained" type="submit" style={{ width: "200px" }}>
            Login
          </Button>
        </form>
      </div>
    </div>
  );
}
