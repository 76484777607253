import axios from "axios";
import { convertToFormData } from "./utils.js";
const HOST =
  window.location.hostname === "localhost"
    ? "https://localhost:7194"
    : "https://trademarkableapi.azurewebsites.net";
const URL_PATH = `${HOST}/api`;

// Submit payment intent
export const submitPaymentIntent = async (payload) => {
  try {
    const { selections } = payload;
    console.log(selections);
    // calculate price
    payload = { price: selections.length };
    const url = `${URL_PATH}/Payment`;
    const response = await axios.post(url, convertToFormData(payload));
    return response.data.result;
  } catch (error) {
    window.alert(error.message);
    return {};
  }
};

// Submit application
export const postApplication = async (payload, withToken) => {
  try {
    const url = `${URL_PATH}/applicationItems`;
    const options = withToken
      ? {
          headers: { Authorization: `Bearer ${localStorage.token}` },
        }
      : {};

    const formData = convertToFormData(payload);
    const response = await axios.post(url, formData, options);
    console.log(response.data);
    return response.data;
  } catch (error) {
    window.alert(error.message);
    console.error(error);
    return error;
  }
};

// Get Access Token - ADMIN
export const getAccessToken = async () => {
  try {
    const url = `${URL_PATH}/IpAustralia/GetAccessToken`;
    const options = {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    };
    const response = await axios.post(url, {}, options);
    return response.data.access_token;
  } catch (error) {
    window.alert(error);
    throw new Error(error);
  }
};

// Get Applications - ADMIN
export const getApplications = async () => {
  try {
    const url = `${URL_PATH}/applicationItems`;
    const options = {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    };
    const response = await axios.get(url, options);
    return response.data;
  } catch (error) {
    window.alert(error.message);
    console.error(error);
    return [];
  }
};

// Update application - ADMIN
export const putApplication = async (payload) => {
  try {
    const url = `${URL_PATH}/applicationItems/${payload.id}`;
    const options = {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    };
    const formData = convertToFormData(payload);
    const response = await axios.put(url, formData, options);
    console.log(response.data);
    return response.data;
  } catch (error) {
    window.alert(error.message);
    console.error(error);
  }
};

// Delete application - ADMIN
export const deleteApplicationItem = async (id) => {
  try {
    const url = `${URL_PATH}/applicationItems/${id}`;
    const options = {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    };
    const response = await axios.delete(url, options);
    console.log(response.data);
    return response.data;
  } catch (error) {
    window.alert(error.message);
    console.error(error);
  }
};

// Email
export const sendEmail = async (payload) => {
  const url = `${URL_PATH}/Email`;

  try {
    const response = await axios.post(url, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const searchClass = async (query) => {
  const url = `${URL_PATH}/IpAustralia/SearchClass`;
  const payload = { query };
  const response = await axios.post(url, payload);
  return response;
};

export const loginUser = async (payload) => {
  try {
    const url = `${URL_PATH}/auth/login`;
    const response = await axios.post(url, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const testAuthAdmin = async () => {
  try {
    const url = `${URL_PATH}/authTest`;
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
    };
    const response = await axios.get(url, options);
    return response;
  } catch (error) {
    window.alert(error);
    return error;
  }
};
