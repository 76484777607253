import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";

// const publishableKey = "pk_test_KntLSssFzFERmlzF0qcqkq8A007mZD8C2m"; // TEST
const publishableKey = "pk_live_ooUgqoi1lhIYdrBnUtt582V800rn9gL8FY"; // PROD
  
function Payment({ apiResult, userInput, setIsStripeSubmitting }) {
  // console.log(apiResult, userInput);
  const stripePromise = loadStripe(publishableKey);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: apiResult.clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm
        stripePaymentIntentId={apiResult.stripePaymentIntentId}
        userInput={userInput}
        setIsStripeSubmitting={setIsStripeSubmitting}
      />
    </Elements>
  );
}

export default Payment;
