import { Button } from "@mui/material";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";

export default function AppHeader() {
  const location = useLocation();
  const navigate = useNavigate();

  // NOTE: THERE IS A BUG WHEN USING APP MENU
  const handleOpenClick = (e) => {
    e.preventDefault();
    e.target.classList.add("hide");
    document.querySelector(".mobile-close").classList.remove("hide");
    document.querySelector(".site-header .site-nav").style.display = "block"
  };

  const handleCloseClick = (e) => {
    e.preventDefault();
    e.target.classList.add("hide");
    document.querySelector(".mobile-open").classList.remove("hide");
    document.querySelector(".site-header .site-nav").style.display = "none"
  };

  const Logout = () => {
    const handleLogout = () => {
      localStorage.clear();
      navigate("/login");
    };
    return location.pathname === "/admin" ? (
      <Button variant="outlined" onClick={handleLogout}>
        Logout
      </Button>
    ) : null;
  };

  return (
    <header className="site-header">
      <div className="container">
        <Link className="site-logo" to="/" rel="home">
          Trademarkable
        </Link>
        {/* TODO: REDO LATER */}
        <a href="./" className="mobile-open" onClick={handleOpenClick}>
          {" "}
        </a>
        <a href="./" className="mobile-close hide" onClick={handleCloseClick}>
          {" "}
        </a>
        <nav className="site-nav" aria-label="Top Menu">
          <div className="menu-primary-container">
            <ul id="menu-primary" className="main-menu">
              <li id="faq" className="menu-item">
                <NavLink to="/faq">FAQ</NavLink>
              </li>
              <li id="about" className="menu-item">
                <NavLink to="/about">About</NavLink>
              </li>
              <li id="pricing" className="menu-item">
                <NavLink to="/pricing">Pricing</NavLink>
              </li>
              <li id="blog" className="menu-item">
                <NavLink to="/blog">Blog</NavLink>
              </li>
              <li id="contactUs" className="menu-item">
                <NavLink to="/contact-us">Contact Us</NavLink>
              </li>
              <li id="apply" className="btn">
                <NavLink to="/trademarkable/trademark">Apply</NavLink>
              </li>
              <Logout />
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}
