import * as React from "react";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import countryList from "./json/countriesProd.json";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { getCountryDialCode } from "./utils/utils";
import { ALPHA_NUMERIC_DASH_REGEX, EMAIL_REGEX } from "./ApplicationTradeMarkDetails";

export default function ApplicationAddressForm({
  setFormValues,
  formValues,
  onSubmitError,
  setOnSubmitError,
}) {
  const [inputValue, setInputValue] = React.useState("");

  const handleChange = (e) => {
    setOnSubmitError({});
    const obj = { [e.target.name]: e.target.value };
    if (e.target.name === "ownerType" && e.target.value === "2") {
      setFormValues((prev) => ({ ...prev, ...obj, abnAcn: "", ownerName: "" }));
    } else if (e.target.name === "ownerType" && e.target.value === "1") {
      setFormValues((prev) => ({
        ...prev,
        ...obj,
        lastName: "",
        firstName: "",
      }));
    } else {
      setFormValues((prev) => ({ ...prev, ...obj }));
    }
  };

  const handleCountryChange = (value) => {
    const obj = { country: value };
    if (value?.countryCode === "AU") {
      setFormValues((prev) => ({ ...prev, ...obj, postcode: "", state: "" }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        ...obj,
        abnAcn: "",
        postcode: "",
        state: "",
      }));
    }
  };

  const validateText = (value, maxLength = 100) => {
    if (!value) return "";
    const match = ALPHA_NUMERIC_DASH_REGEX.test(value);
    if (!match) {
      return "Please input English characters only";
    }
    if (value.length > maxLength) {
      return `Maximum character length: ${maxLength}`;
    }
    return "";
  };

  const validateEmail = (value, maxLength = 100) => {
    if (!value) return "";
    const match = ALPHA_NUMERIC_DASH_REGEX.test(value);
    if (!match) {
      return "Please input English characters only";
    }
    if (!EMAIL_REGEX.test(value)) {
      return "Invalid email format";
    }
    if (value.length > maxLength) {
      return `Maximum character length: ${maxLength}`;
    }

    return "";
  }

  const validatePhone = (value) => {
    if (!value) return "";
    const match = /^\d+$/.test(value);
    if (!match) {
      return "Phone number is in incorrect format";
    }
    return "";
  }

  return (
    <div id="applicationOwnership">
      <h2>Ownership</h2>
      <Box
        id="ownerShipForm"
        component="form"
        sx={{
          "& .MuiTextField-root": { mb: 2 },
          mb: 2,
        }}
        autoComplete="off"
      >
        <FormControl sx={{ mb: 2 }}>
          <FormLabel id="ownerType">The owner of the trademark is:</FormLabel>
          <RadioGroup
            row
            aria-labelledby="radio-option-5"
            name="ownerType"
            value={formValues.ownerType}
            onChange={handleChange}
          >
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="Company/Organisation"
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label="Individual"
            />
          </RadioGroup>
        </FormControl>

        <Autocomplete
          size="small"
          id="country"
          value={formValues.country}
          onChange={(_event, newValue) => {
            handleCountryChange(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          name="country"
          options={countryList}
          autoHighlight
          getOptionLabel={(option) => option.countryName}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.countryName}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Country"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />

        {formValues.ownerType === "1" && (
          <>
            <TextField
              error={Boolean(validateText(formValues.ownerName))}
              helperText={validateText(formValues.ownerName)}
              required
              size="small"
              id="ownerName"
              name="ownerName"
              label="Company/Organisation Name"
              placeholder="Enter full legal entity name, e.g., ABC Pty Ltd"
              fullWidth
              autoComplete="ownerName"
              variant="outlined"
              value={formValues.ownerName}
              onChange={handleChange}
            />
            {formValues.country?.countryCode === "AU" && (
              <TextField
                error={Boolean(onSubmitError.abnAcn)}
                helperText={onSubmitError.abnAcn}
                required
                size="small"
                id="abnAcn"
                name="abnAcn"
                label="Australian Company Number (ACN)"
                fullWidth
                autoComplete="abnAcn"
                variant="outlined"
                value={formValues.abnAcn}
                onChange={handleChange}
              />
            )}
          </>
        )}

        {formValues.ownerType === "2" && (
          <>
            <TextField
              error={Boolean(validateText(formValues.firstName))}
              helperText={validateText(formValues.firstName)}
              required
              size="small"
              id="firstName"
              name="firstName"
              label="First Name"
              fullWidth
              autoComplete="firstName"
              variant="outlined"
              value={formValues.firstName}
              onChange={handleChange}
            />
            <TextField
              error={Boolean(validateText(formValues.lastName))}
              helperText={validateText(formValues.lastName)}
              required
              size="small"
              id="lastName"
              name="lastName"
              label="Last Name"
              fullWidth
              autoComplete="lastName"
              variant="outlined"
              value={formValues.lastName}
              onChange={handleChange}
            />
          </>
        )}

        <TextField
          error={Boolean(validateEmail(formValues.email))}
          helperText={validateEmail(formValues.email)}
          required
          size="small"
          id="email"
          name="email"
          label="Email"
          placeholder="All correspondence will be directed to this email"
          fullWidth
          autoComplete="email"
          variant="outlined"
          value={formValues.email}
          onChange={handleChange}
        />

        <TextField
          error={Boolean(validatePhone(formValues.phone))}
          helperText={validatePhone(formValues.phone)}
          required
          size="small"
          id="phone"
          name="phone"
          label="Phone Number"
          fullWidth
          variant="outlined"
          value={formValues.phone}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {getCountryDialCode(formValues.country?.countryCode)}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          error={Boolean(validateText(formValues.addressLine1))}
          helperText={validateText(formValues.addressLine1)}
          required
          size="small"
          id="addressLine1"
          name="addressLine1"
          label="Address line 1"
          fullWidth
          autoComplete="address-line1"
          variant="outlined"
          value={formValues.addressLine1}
          onChange={handleChange}
        />

        <TextField
          error={Boolean(validateText(formValues.addressLine2))}
          helperText={validateText(formValues.addressLine2)}
          size="small"
          id="addressLine2"
          name="addressLine2"
          label="Address line 2 (optional)"
          fullWidth
          autoComplete="address-line2"
          variant="outlined"
          value={formValues.addressLine2}
          onChange={handleChange}
        />
        <TextField
          error={Boolean(validateText(formValues.addressLine3))}
          helperText={validateText(formValues.addressLine3)}
          size="small"
          id="addressLine3"
          name="addressLine3"
          label="Address line 3 (optional)"
          fullWidth
          autoComplete="address-line3"
          variant="outlined"
          value={formValues.addressLine3}
          onChange={handleChange}
        />

        <TextField
          error={Boolean(validateText(formValues.suburb, 40))}
          helperText={validateText(formValues.suburb, 40)}
          required
          size="small"
          id="suburb"
          name="suburb"
          label="City/Suburb"
          fullWidth
          autoComplete="suburb"
          variant="outlined"
          value={formValues.suburb}
          onChange={handleChange}
        />
        <TextField
          error={Boolean(onSubmitError.postcode)}
          helperText={onSubmitError.postcode}
          required={formValues.country?.countryCode === "AU"}
          size="small"
          id="postcode"
          name="postcode"
          label={`Postcode${formValues.country?.countryCode === "AU" ? "" : " (optional)"}`}
          fullWidth
          autoComplete="postal-code"
          variant="outlined"
          value={formValues.postcode}
          onChange={handleChange}
        />

        {formValues.country?.countryCode !== "AU" ? (
          <TextField
            error={Boolean(validateText(formValues.state, 40))}
            helperText={validateText(formValues.state, 40)}
            size="small"
            id="state"
            name="state"
            label="State (optional)"
            fullWidth
            autoComplete="state"
            variant="outlined"
            value={formValues.state}
            onChange={handleChange}
          />
        ) : (
          <FormControl size="small" fullWidth required>
            <InputLabel id="state-select-label">State</InputLabel>
            <Select
              name="state"
              labelId="state-select-label"
              id="state-select-label"
              value={formValues.state}
              onChange={handleChange}
              label="state"
            >
              <MenuItem value={"ACT"}>Australian Capital Territory</MenuItem>
              <MenuItem value={"NSW"}>New South Wales</MenuItem>
              <MenuItem value={"NT"}>Northern Territory</MenuItem>
              <MenuItem value={"QLD"}>Queensland</MenuItem>
              <MenuItem value={"SA"}>South Australia</MenuItem>
              <MenuItem value={"TAS"}>Tasmania</MenuItem>
              <MenuItem value={"VIC"}>Victoria</MenuItem>
              <MenuItem value={"WA"}>Western Australia</MenuItem>
            </Select>
          </FormControl>
        )}
      </Box>
    </div>
  );
}
