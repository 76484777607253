import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import HeroButtons from "../components/HeroButtons";
export default function About() {
  return (
    <div id="page" className="site">
      <AppHeader />

      <div id="content" className="site-content">
        <section className="hero hero-page">
          <div className="container">
            <div className="hero-content">
              <h1>Let us help you protect and register your brand</h1>
              <h3 className="hero-sub">Apply for your trademark online</h3>
              <HeroButtons />
            </div>
            <div className="page-content">
              <div
                id="post-55"
                className="post-55 page type-page status-publish hentry"
              >
                <h3>About Us</h3>
                <p>
                  Trademarkable is an Australian online platform designed to
                  simplify the trademark application process, making it
                  accessible and affordable for everyone. We understand that
                  your brand is one of your most valuable assets, and our
                  mission is to help you protect it with ease and efficiency.
                </p>
                <p>
                  With our intuitive platform, you can quickly enter your
                  trademark details into our online form, and once submitted,
                  our experienced team handles the preparation and filing of
                  your application with IP Australia within 1-2 business days.
                  We strive to make this process smooth and hassle-free so that
                  you can focus on what truly matters—growing your business.
                </p>
                <p>
                  At Trademarkable, we believe that brand protection should be
                  straightforward and within reach for all businesses. Start
                  your trademark application today and take the first step
                  toward securing your brand!
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
