import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import AutoCompleteCountry from "./AutoCompleteCountry";

export default function FormDialog({
  open,
  targetApplication,
  handleCreate,
  handleCloseDialog,
  handleSubmit,
}) {

  const handleClick = e => {
    e.preventDefault();
    const form = document.querySelector("#editForm");
    const formData = new FormData(form);
    const payload = Object.fromEntries(formData);

    // Re-add missing form items
    payload.id = targetApplication.id;
    payload.applicationIdentifier = targetApplication.applicationIdentifier
    payload.applicationFee = targetApplication.applicationFee
    // HOTFIX: Update this later
    payload.hasAgreedToTOS = payload.hasAgreedToTOS === "true" ? true : false;
    console.log(payload);
    handleSubmit(payload);
  }

  const actionType = targetApplication.id ? "Edit" : "Create"

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleCreate}>
        Create Application
      </Button>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{actionType} Application</DialogTitle>
        <Box
          id="editForm"
          component="form"
          onSubmit={handleClick}
          autoComplete="off"
          sx={{
            "& .MuiTextField-root": { margin: "8px 0" },
          }}
        >
          <DialogContent>
            <div>
              <FormControl size="small" fullWidth>
                <InputLabel id="trademarkType-select-label">
                  Trademark Type
                </InputLabel>
                <Select
                  name="trademarkType"
                  labelId="trademarkType-select-label"
                  id="trademarkType-select-label"
                  defaultValue={targetApplication.trademarkType || ""}
                  label="Trademark Type"
                  required
                >
                  <MenuItem value={1}>Word</MenuItem>
                  <MenuItem value={2}>Logo</MenuItem>
                </Select>
              </FormControl>

              <TextField
                size="small"
                name="trademarkText"
                placeholder="trademarkText"
                defaultValue={targetApplication.trademarkText}
                fullWidth
              />
              <FormControl size="small" fullWidth>
                <InputLabel id="ownerType-select-label">Owner Type</InputLabel>
                <Select
                  name="ownerType"
                  labelId="ownerType-select-label"
                  id="ownerType-select-label"
                  defaultValue={targetApplication.ownerType || ""}
                  label="ownerType"
                >
                  <MenuItem value={1}>Company</MenuItem>
                  <MenuItem value={2}>Individual</MenuItem>
                </Select>
              </FormControl>

              <TextField
                size="small"
                name="ownerName"
                placeholder="ownerName"
                defaultValue={targetApplication.ownerName}
                fullWidth
              />
              <TextField
                size="small"
                name="firstName"
                placeholder="firstName"
                defaultValue={targetApplication.firstName}
                fullWidth
              />
              <TextField
                size="small"
                name="lastName"
                placeholder="lastName"
                defaultValue={targetApplication.lastName}
                fullWidth
              />
              <TextField
                size="small"
                name="abnAcn"
                placeholder="abnAcn"
                defaultValue={targetApplication.abnAcn}
                fullWidth
              />
              <TextField
                size="small"
                name="email"
                placeholder="email"
                defaultValue={targetApplication.email}
                fullWidth
              />
              <TextField
                size="small"
                name="phone"
                placeholder="phone"
                defaultValue={targetApplication.phone}
                fullWidth
              />
              <TextField
                size="small"
                name="addressLine1"
                placeholder="addressLine1"
                defaultValue={targetApplication.addressLine1}
                fullWidth
              />
              <TextField
                size="small"
                name="addressLine2"
                placeholder="addressLine2"
                defaultValue={targetApplication.addressLine2}
                fullWidth
              />
              <TextField
                size="small"
                name="addressLine3"
                placeholder="addressLine3"
                defaultValue={targetApplication.addressLine3}
                fullWidth
              />
              <TextField
                size="small"
                name="suburb"
                placeholder="suburb"
                defaultValue={targetApplication.suburb}
                fullWidth
              />
              <TextField
                size="small"
                name="postcode"
                placeholder="postcode"
                defaultValue={targetApplication.postcode}
                fullWidth
              />
              <TextField
                size="small"
                name="state"
                placeholder="state"
                defaultValue={targetApplication.state}
                fullWidth
              />
              {/* <FormControl size="small" fullWidth>
                <InputLabel id="state-select-label">State</InputLabel>
                <Select
                  name="state"
                  labelId="state-select-label"
                  id="state-select-label"
                  defaultValue={targetApplication.state || ""}
                  label="state"
                >
                  <MenuItem value={"ACT"}>Australian Capital Territory</MenuItem>
                  <MenuItem value={"NSW"}>New South Wales</MenuItem>
                  <MenuItem value={"NT"}>Northern Territory</MenuItem>
                  <MenuItem value={"QLD"}>Queensland</MenuItem>
                  <MenuItem value={"SA"}>South Australia</MenuItem>
                  <MenuItem value={"TAS"}>Tasmania</MenuItem>
                  <MenuItem value={"VIC"}>Victoria</MenuItem>
                  <MenuItem value={"WA"}>Western Australia</MenuItem>
                </Select>
              </FormControl> */}

              <AutoCompleteCountry country={targetApplication.country} />
              <hr />
              <Box
                name="trademarkLogo"
                as="input"
                type="file"
                style={{ marginTop: "8px" }}
              />
              <hr />
              <TextField
                size="small"
                name="selections"
                placeholder="selections"
                defaultValue={targetApplication.selections}
                fullWidth
                multiline
                sx={{ fontFamily: "monospace" }}
              />
              <FormControl size="small" fullWidth>
                <InputLabel id="hasAgreedToTOS-select-label">
                  hasAgreedToTOS
                </InputLabel>
                <Select
                  name="hasAgreedToTOS"
                  labelId="hasAgreedToTOS-select-label"
                  id="hasAgreedToTOS-select-label"
                  defaultValue={
                    targetApplication.hasAgreedToTOS ? "true" : "false"
                  }
                  label="hasAgreedToTOS"
                  required
                >
                  <MenuItem value={"true"}>True</MenuItem>
                  <MenuItem value={"false"}>False</MenuItem>
                </Select>
              </FormControl>

              {/* <TextField
                size="small"
                name="applicationIdentifer"
                placeholder="applicationIdentifer e.g. ATSU-XXX"
                defaultValue={targetApplication.applicationIdentifer}
                fullWidth
              /> */}

              <TextField
                size="small"
                name="applicationNumber"
                placeholder="applicationNumber"
                defaultValue={targetApplication.applicationNumber}
                fullWidth
              />

              <TextField
                size="small"
                name="contactIdentifier"
                placeholder="contactIdentifier"
                defaultValue={targetApplication.contactIdentifier}
                fullWidth
              />

              <TextField
                size="small"
                name="imageIdentifier"
                placeholder="imageIdentifier"
                defaultValue={targetApplication.imageIdentifier}
                fullWidth
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} type="button">
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
