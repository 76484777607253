import axios from "axios";

// const HOST_IP_4AUS = "https://test.api.ipaustralia.gov.au/public/ipright-management-b2b-api/v1";
const HOST_IP_AUS = "https://production.api.ipaustralia.gov.au/public/ipright-management-b2b-api/v1";
const HOST =
  window.location.hostname === "localhost"
    ? "https://localhost:7194"
    : "https://trademarkableapi.azurewebsites.net";
const URL_PATH = `${HOST}/api`;

// 1. Create Contact
export const createNewContact = async (payload) => {
  try {
    const url = `${URL_PATH}/IpAustralia/CreateContact`;
    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.ipToken}`,
      },
    };
    const response = await axios.post(url, payload, options);
    console.log(response.data.message);
    return response.data;
  } catch (error) {
    window.alert(error.message);
    console.error(error.response.data.errorMessages[0].message);
    return error.response.data;
  }
};

//2. Create Trademark
export const createNewTrademark = async (payload) => {
  const url = `${URL_PATH}/IpAustralia/CreateTrademark`;
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.ipToken}`,
    },
  };

  try {
    const response = await axios.post(url, payload, options);
    console.log(response);
    return response.data
  } catch (error) {
    console.log(error)
    window.alert(error.response.data.errorMessages[0].message);
    return false;
  }
};

// Upload Trademark (Optional)
export const uploadAttachment = async (fileUrl) => {
  const url = `${URL_PATH}/IpAustralia/UploadFileFromUrl`;
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.ipToken}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.post(url, { fileUrl }, options);
    return response.data
  } catch (error) {
    console.log(error);
    return error.response.data.errorMessages[0].message
  }
};

// AddressBook
export const getAddressBook = async () => {
  
  const url = `${HOST_IP_AUS}/me/contacts`;
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.ipToken}`,
    },
  };
  try {
    const response = await axios.get(url, options);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteContact = async (id) => {
  const url = `${HOST_IP_AUS}/me/contacts/${id}`;
  const options = {
    headers: {
      "Consent-Flag": true,
      Authorization: `Bearer ${localStorage.ipToken}`,
      "Content-Type": "application/json",
    },
    data: ""
  };
  try {
    const response = await axios.delete(url, options);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
