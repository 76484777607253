import React from 'react'

export default function ErrorNotification({message}) {
  return (
    <div>
      <p className="error" style={{ color: "red" }}>
        {message}
      </p>
    </div>
  );
}
