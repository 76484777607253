import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import HeroButtons from "../components/HeroButtons";

export default function Privacy2() {
  return (
    <div id="page" className="site">
      <AppHeader />
      <div id="content" className="site-content">
        <section className="hero hero-page">
          <div className="container">
            <div className="hero-content">
              <h1>Privacy Policy</h1>
              <h3 className="hero-sub">Protect your brand!</h3>
              <HeroButtons />
            </div>
            <div className="page-content">
              <div id="post-3" className="post-3 page type-page status-publish hentry">
                <p>
                  This website is operated by IPABLE Pty Ltd trading as Trademarkable (A.B.N. 34 678 125 098) (referred to as &quot;Trademarkable&quot;, &quot;we&quot;,
                  &quot;our&quot; and &quot;us&quot;). Trademarkable recognises that the privacy of your personal information is important to you. Unless you directly give us
                  consent to do otherwise, we will only collect and use your personal information as set out below. Trademarkable abides by the &quot;Australian Privacy
                  Principles&quot; contained in the Privacy Act 1988 (Cth). This Privacy Policy outlines how Trademarkable will handle and manage the personal information we
                  collect about you. By providing your personal information to us and by using our website, you consent to the collection of your personal information by
                  Trademarkable and its use and disclosure in accordance with this Privacy Policy. We acknowledge that you may provide personal information to us without having
                  sought our prior consent. In such cases, we will handle your personal information in accordance with this Privacy Policy.
                </p>
                <h4>What information do we collect?</h4>
                <p>
                  We may collect certain types of personal information from you. Personal information is any information that can be used to identify you, such as your name,
                  address, phone numbers (including your mobile phone number), email address, physical/postal address, ABN/ACN, company name, credit card information, purchase
                  history and any other information required by us or provided by you for billing purposes.
                </p>
                <h4>Why and how do we collect personal information?</h4>
                <p>
                  Generally, we collect personal information about customers to provide a range of services to meet your needs and enable us to conduct our business. We will only
                  collect personal information by lawful and fair means. Any personal information given to you to Trademarkable will only be used, collected, held and disclosed for
                  the following purposes:
                </p>
                <ul>
                  <li> To complete an online service order via our website;</li>
                  <li> To effect delivery of any service ordered;</li>
                  <li> To deal with your queries promptly, whether by email, telephone or mail;</li>
                  <li> To provide you with better service and to better understand your needs;</li>
                  <li> To respond to you when you contact us with an enquiry;</li>
                  <li>To charge you and collect money that you owe us, including authorising and processing credit card transaction;</li>
                  <li>To respond to lawful requests from public and/or government authorities, including to comply with law enforcement regulations; and</li>
                  <li> If required by any relevant law.</li>
                </ul>
                <p>We will take reasonable steps to ensure that the personal information we collect, use or disclose is accurate, up to date, complete and relevant.</p>

                <h4>Disclosing of personal information</h4>
                <p>
                  We take all reasonable steps to maintain the security and integrity of your personal information and to protect your personal information from misuse,
                  interference, loss and unauthorised access, modification or disclosure. However, we may disclose your personal information to the following parties:
                </p>
                <ul>
                  <li> any business that supports our website and services, such as the IT system or processing payment data centre that we use for our website</li>
                  <li> a person who can require us to supply your personal information (e.g. a law enforcement agency or regulatory authority)</li>
                  <li> any other person with your consent</li>
                  <li> any other person authorised by applicable law.</li>
                </ul>
                <p>We will not disclose your personal information to overseas recipients without your consent, unless required or authorised by law.</p>
                <h4>Cookies</h4>
                <p>
                  Also, we may use &quot;cookies&quot; on our websites, which may collect personal information. A cookie is a small software message sent to your web browser by our
                  web server. Your browser stores the message in a file and the message is then sent back to our servers each time your browser requests a page from our servers. We
                  use cookies to gain statistics on which areas of our sites attract traffic. We also use cookies to improve your experience with our websites. For example, cookies
                  allow us to recognise whether or not you are returning to our websites. In addition, we use third parties who use cookies to serve ads based on past visits to our
                  website. Most Internet browsers are set up to accept cookies. If you do not wish to receive cookies, you will need to adjust your browser&#39;s settings to refuse
                  all cookies or notify you each time a cookie is sent to your computer. You might not be able to use the full functionality of the Trademarkable website by
                  refusing the use of cookies. When you click on links and banners on our sites that take you to third-party websites, you will be subject to that third-party&#39;s
                  privacy policies. While we support the protection of Privacy on the internet, we cannot be held responsible for the actions of any third-party websites.
                </p>
                <h4>Accessing and correcting your personal information</h4>
                <p>
                  Under the Privacy Act, you have a right to seek access to information that Trademarkable holds about you. You also have the right to ask us to correct information
                  about you that is inaccurate, incomplete or out of date. While Trademarkable takes all reasonable precautions to ensure that your personal information is complete
                  and up to date, please notify us of any changes or errors in your personal information held by us. We will comply with any such request except where the Privacy
                  Act allows us to refuse to do so. We will respond to your request for access or correction within a reasonable time, usually within 30 days.
                </p>
                <p>
                  If we refuse to give you access to your personal information or to correct it, we will provide you with written reasons for the refusal and information about how
                  you can complain about the refusal.
                </p>
                <h4>Changes to this Privacy Policy</h4>
                <p>
                  This is our current Privacy Policy outlining our personal information management practices. This Privacy Policy replaces any other privacy policy published by us
                  to date. We may vary this policy at any time. We will notify you of any changes by posting an updated version of the policy on our website.
                </p>
                <h4>Contact</h4>
                <p>
                  You can contact us at <a href="mailto:mail@trademarkable.com.au">mail@trademarkable.com.au</a> if you have any questions about this Privacy Policy or our privacy
                  practices, or want to access or correct your personal information. If you are not satisfied with our response to your privacy-related complaint, you can contact
                  the Office of the Australian Information Commissioner (OAIC) at{" "}
                  <a target="_blank" rel="noreferrer" href="https://www.oaic.gov.au/">
                    https://www.oaic.gov.au
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
