import * as React from "react";
import Payment from "./components/Payment";
import OrderSummary from "./components/OrderSummary"
import "./ApplicationSummaryPayment.css";
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';

export default function ApplicationSummaryPayment({
  setFormValues,
  formValues,
  selections,
  totalCost,
  apiResult,
  setIsStripeSubmitting,
  hasTermsAgreementError,
}) {
  
  const handleCheckChange = (e) => {
    const hasAgreedToTOS = e.currentTarget.checked;
    setFormValues({...formValues, hasAgreedToTOS })
  }

console.log({...formValues, selections})
  return (
    <React.Fragment>
      <div id="summaryPage">
        <div className="ac-container">
          <div className="ac-column">
            <OrderSummary
              userInput={formValues}
              totalCost={totalCost}
              selections={selections}
            />
          </div>
          <div className="ac-column">
            <Payment
              apiResult={apiResult}
              userInput={{ ...formValues, selections }}
              setIsStripeSubmitting={setIsStripeSubmitting}
            />
            <div className="terms-agreement-container">
              <div>
                <Checkbox
                  error="true"
                  id="termsAgreementCheckbox"
                  name="terms"
                  onChange={handleCheckChange}
                />
              </div>
              <p>
                By confirming your details and proceeding with payment of your
                trademark application, you acknowledge that you have read and
                agree to the{" "}
                <a
                  href="/terms-of-service/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href="/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
            {hasTermsAgreementError && (
              <FormHelperText
                id="termsAgreementError"
                sx={{ color: "red", paddingLeft: "12px" }}
              >
                You must agree to the Terms of Use and Privacy Policy.
              </FormHelperText>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
