import { Link } from "react-router-dom";

export default function AppFooter() {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="site-footer_top">
          <h2>Protect your most valuable business asset.</h2>
          <Link
            to="/trademarkable/trademark/"
            className="button button-primary"
          >
            Trademark your brand
          </Link>
        </div>
        <hr />
        <div className="site-footer_bottom">
          <Link className="site-logo" to="/" rel="home">
            Trademarkable
          </Link>
          <div>
            <nav className="site-nav" aria-label="Footer Menu">
              <div className="menu-footer-container">
                <ul id="menu-footer" className="main-menu">
                  <li>
                    <Link to="/faq/">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/about/">About</Link>
                  </li>
                  <li>
                    <Link to="/blog/">Blog</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-service/">Terms of Service</Link>
                  </li>
                  <li>
                    <Link rel="privacy-policy" to="/privacy-policy/">
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>{" "}
            </nav>
            <p className="copyright">
              © {new Date().getFullYear()} Trademarkable. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
