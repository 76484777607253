import React from "react";
import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/AppFooter";
export default function Post3() {
  return (
    <div id="page" className="site">
      <AppHeader />
      <div id="content" className="site-content">
        <section className="section-blog">
          <div className="container">
            <div
              id="post3"
              className="blog-post_feature"
            ></div>
          </div>
          <div className="container container-md">
            <div className="page-content">
              <h1>International Women's Day 2022</h1>
              <p>
                Trademarkable is delighted and proud to support International
                Women's Day 2022!
              </p>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
