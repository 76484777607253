import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import { Link } from "react-router-dom";

export default function Pricing() {
  return (
    <div id="page" className="site">
      <AppHeader />
      <div id="content" className="site-content">
        <section className="hero hero-page">
          <div className="container">
            <div className="hero-content" style={{marginTop: 0}}>
              <div className="pricing-table">
                <div className="pricing-table-header">Cost Breakdown</div>
                <div className="pricing-row">
                  <div className="col-1">
                    <b className="pricing-number">$500</b>
                    <span>including Govt Fees</span>
                  </div>
                  <div className="col-2">Trademark application in 1 class</div>
                </div>
                <div className="pricing-row">
                  <div className="col-1">
                    <b className="pricing-number">$300</b>
                    <span>including Govt Fees</span>
                  </div>
                  <div className="col-2">Each additional class</div>
                </div>
              </div>

              <div className="hero-buttons">
                <Link
                  to="/trademarkable/trademark/"
                  className="button button-primary"
                >
                  Trademark Your Brand
                </Link>
              </div>
            </div>
            <div className="page-content">
              <div
                id="post-55"
                className="post-55 page type-page status-publish hentry"
              >
                <h3>Pricing</h3>
                <p>
                  Protect your brand with confidence. While trademark
                  registration in Australia can be complex, Trademarkable
                  simplifies the entire process with transparent, fixed-price
                  packages. Our pricing is straightforward - it's based solely
                  on the number of classes your trademark covers. No hidden
                  fees, no surprises - just a straightforward way to file your
                  trademark application with ease.
                </p>
                <p>
                  If your trademark gets accepted and registered, we'll let you
                  know at no extra cost. However, if IP Australia raises any
                  issues, you'll need help from a trademark attorney. Don't
                  worry - we're here to help guide you through that process if
                  needed!
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
