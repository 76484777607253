import React from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postApplication } from "../api/services";
import { getCountryDialCode } from "../utils/utils";
import ErrorSubmittingDialog from "./ErrorSubmittingDialog";

function PaymentForm({
  userInput,
  stripePaymentIntentId,
  setIsStripeSubmitting,
}) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const closeDialog = () => {
    setIsOpen(false);
  };

  // TODO: Lift the state up for this function
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // Validate general details before processing
    const {error} = await elements.submit()
    if(error){
      setIsStripeSubmitting(false);
      return;
    }

    setIsProcessing(true);
    setIsStripeSubmitting(true);

    try {
      // Clean payload
      const serializedSelections = userInput.selections.map(
        ({ number, suggestions }) => {
          const obj = {
            classNumber: number,
            classDescriptions: suggestions.map(({ name }) => name),
          };
          return obj;
        }
      );
      // Create Order/Application - Finalise Data
      userInput.selections = JSON.stringify(serializedSelections);
      userInput.country = userInput.country.countryCode;
      userInput.phone = `${getCountryDialCode(userInput.country)} ${userInput.phone}`;

      const response = await postApplication({
        ...userInput,
        StripeCheckoutId: stripePaymentIntentId,
      });

      if (response.message) {
        setIsProcessing(false);
        setIsStripeSubmitting(false);
        setIsOpen(true)
        return;
      }
    } catch (error) {
      setIsProcessing(false);
      setIsStripeSubmitting(false);
      window.alert(error.message)
      return;
    }

    try {
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: "https://example.com/order/123/complete", // Not used
          payment_method_data: {
            billing_details: {
              name: 'Abraham Song',
              email: 'abraham.song@gmail.com',
            }
          },
        },
        redirect: "if_required",
      });

      if (result.error) {
        // Show error to your customer - Currently shows default UI error
        setIsProcessing(false);
        setIsStripeSubmitting(false);
        // console.log(result.error.message);
        // window.alert(result.error.message)
      } else {
        // Navigate
        const { status } = result.paymentIntent;
        if (status === "succeeded") {
          const response = {}; // PLACEHOLDER for postApplication response
          navigate(`/order/orderConfirmed`, {
            state: { response },
          });
        }
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        console.log(result);
      }
    } catch (ex) {
      window.alert(ex.message);
      setIsProcessing(false);
      setIsStripeSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <ErrorSubmittingDialog isOpen={isOpen} handleClose={closeDialog}/>
      <button
        style={{ display: "none" }}
        id="stripeSubmit"
        disabled={!stripe || isProcessing}
      >
        Submit
      </button>
    </form>
  );
}

export default PaymentForm;
