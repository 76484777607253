import React from "react";
import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/AppFooter";

export default function Post2() {
  return (
    <div id="page" className="site">
      <AppHeader />
      <div id="content" className="site-content">
        <section className="section-blog">
          <div className="container">
            <div
              id="post2"
              className="blog-post_feature"
            ></div>
          </div>
          <div className="container container-md">
            <div className="page-content">
              <h1>.au domain names</h1>
              <p>
                From 24 March 2022, .au domain names will be available for
                purchase.{" "}
                <span>
                  All business owners should consider purchasing their .au
                  equivalent of their current domain name to prevent potential
                  interference or confusion. Although you will need to have a
                  presence in Australia to purchase a .au domain name, this can
                  be achieved by having an Australian registered trade mark
                  which is an exact match to the domain name sought.
                </span>
              </p>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
