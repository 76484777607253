import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import HeroButtons from "../components/HeroButtons";

export default function TermsOfService2() {
  return (
    <div id="page" className="site">
      <AppHeader />
      <div id="content" className="site-content">
        <section className="hero hero-page">
          <div className="container">
            <div className="hero-content">
              <h1>Terms of Service</h1>
              <h3 className="hero-sub">Protect your brand!</h3>
              <HeroButtons />
            </div>
            <div className="page-content">
              <p>
                This website is operated by IPABLE Pty Ltd trading as Trademarkable (A.B.N. 34 678 125 098) (referred to as &quot;Trademarkable&quot;, &quot;we&quot;,
                &quot;our&quot; and &quot;us&quot;). Your use of our website and services is subject to the following terms and conditions.
              </p>
              <h4>1. Purpose and Acceptance</h4>
              <p>
                1.1 These Terms govern your use of, access to, and purchase of services using the Trademarkable platform (&quot;Platform&quot;). The services relate to the filing
                of trade mark applications in Australia.
              </p>
              <p>1.2 By accessing or using the Platform, you agree to these Terms. If you do not agree, you may not use the Platform or our services.</p>
              <p>1.3 We may amend these Terms at any time in our sole discretion. Any changes will be posted on the Platform and will apply to your subsequent use.</p>
              <p>1.4 You affirm that you are 18 years or over, or otherwise possess legal parental or guardian consent, and are not under any legal disability.</p>
              <h4>2. Platform Access and Availability</h4>
              <p>2.1 Subject to these Terms, we grant you a personal, non-exclusive, non-transferable, limited right to access and use the Platform.</p>
              <p>
                2.2 While we endeavour to ensure the Platform&#39;s continuous availability, we do not guarantee uninterrupted access. The Platform is provided on an
                &quot;as-is&quot; and &quot;as-available&quot; basis.
              </p>
              <p>2.3 We reserve the right to change or discontinue any feature or service on the Platform at any time.</p>

              <h4>3. Account Creation and Security</h4>
              <p>3.1 To use our services, you might be required to create an account. You are responsible for maintaining the confidentiality of your account details.</p>
              <p>3.2 You must provide accurate, current, and complete information during the registration process and keep your account information updated.</p>

              <h4>4. Services and Applications</h4>
              <p>
                4.1 Our Platform allows you to prepare trade mark applications. We will file these applications with IP Australia on your behalf upon full payment of the applicable
                fees.
              </p>
              <p>4.2 Submission of an application and payment of fees does not guarantee trade mark registration. Registration decisions are made by IP Australia.</p>
              <p>4.3 You are responsible for responding to any queries from IP Australia within the specified timeframes.</p>

              <h4>5. Fees and Payment</h4>
              <p>5.1 Fees for our services are as stated on the Platform in Australian dollars at the time of submission, plus any applicable taxes or government fees.</p>
              <p>5.2 All payments must be made in full at the time of submitting your application.</p>
              <p>5.3 To the extent lawful, all payments are non-refundable.</p>

              <h4>6. Intellectual Property</h4>
              <p>6.1 The Platform contains content protected by our Intellectual Property Rights. You do not acquire any right, title, or interest in this content.</p>
              <p>
                6.2 By uploading any material to the Platform, you grant us a non-exclusive, worldwide, royalty-free, perpetual licence to use and reproduce the material for
                purposes related to your application and our services.
              </p>

              <h4>7. User Obligations and Prohibited Use</h4>
              <p>
                7.1 You warrant that any trade mark applied for is being used or intended to be used by the applicant, or with their consent, in relation to the stated goods or
                services, or there is a bona fide intention for such use.
              </p>
              <p>7.2 You must not use the Platform for any unlawful purpose, to infringe others&#39; rights, or in any way that could damage, disable, or impair the Platform.</p>

              <h4>8. Disclaimer and Limitation of Liability</h4>
              <p>8.1 To the extent permitted by law, we exclude all express and implied warranties regarding the Platform and our services.</p>
              <p>8.2 Our liability for breach of any condition or warranty is limited to re-supplying the services or paying the cost of having the services re-supplied.</p>
              <p>8.3 We are not liable for any loss resulting from delays, failures, or inaccuracies in the transmission of any information through the Platform.</p>

              <h4>9. Privacy</h4>
              <p>
                9.1 Our collection and use of your personal information is governed by our <a href="/privacy-policy" rel="noreferrer">Privacy Policy</a>, which forms part of these Terms.
              </p>

              <h4>10. Termination</h4>
              <p>
                10.1 We may suspend or terminate your access to the Platform at any time, without notice, for any reason, including if we believe you have breached these Terms.
              </p>

              <h4>11. General</h4>
              <p>11.1 These Terms are governed by the laws of New South Wales, Australia. You submit to the exclusive jurisdiction of the courts of New South Wales.</p>
              <p>11.2 If any part of these Terms is found to be invalid or unenforceable, the remaining parts will continue in full force and effect.</p>

              <h4>12. No Lawyer/Attorney-Client Relationship</h4>
              <p>
                12.1 Our operation of the Platform, this website and provision of services is not intended to create and will not create a lawyer/attorney-client relationship with
                you. We are not acting for you in the capacity of a trade mark attorney or lawyer.
              </p>
              <p>
                12.2 Trademarkable provides a platform on which you can prepare trade mark applications yourself. We will lodge these applications with IP Australia on your behalf,
                but we do not provide legal advice or representation.
              </p>
              <p>12.3 While you are welcome to contact us by email, merely contacting us or communicating with us does not create a lawyer/attorney-client relationship.</p>
              <p>12.4 If you require legal advice or representation, we recommend you consult with a qualified trade mark attorney or lawyer.</p>

              <h4>13. Trade Mark Filing</h4>
              <p>
                13.1 Upon receiving all required information and full payment from you, we typically aim to file your trade mark application with IP Australia within two business
                days.
              </p>
              <p>
                13.2 While we strive to meet this timeframe, please note that all timing estimates are provided in good faith and are not guaranteed. Various factors may affect the
                filing process.
              </p>
              <p>13.3 You acknowledge that the filing process may be impacted by factors beyond our control, including but not limited to:</p>
              <ol type="a">
                <li>Technical issues with our Platform</li>
                <li>Technical issues with IP Australia&#39;s systems</li>
                <li>Unexpected high volumes of applications</li>
                <li>Public holidays or unforeseen circumstances</li>
              </ol>
              <p>13.4 In the event of any delay in filing your application, to the fullest extent permitted by law:</p>
              <ol type="a">
                <li>We shall not be held liable for any consequences of such delay</li>
                <li>You will not have the right to cancel your application solely on the grounds of this delay</li>
                <li>We will make reasonable efforts to complete the filing as soon as practicable</li>
              </ol>
              <h4>14. Contact</h4>
              <p>
                You can contact us at <a href="mailto:mail@trademarkable.com.au">mail@trademarkable.com.au</a> if you have any questions about these Terms or our services.
              </p>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
