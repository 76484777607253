export const convertToFormData = (payload) => {
  let formData = new FormData();
  Object.keys(payload).forEach((key) => {
    if (payload[key] !== null && payload[key] !== undefined) {
      formData.append(key, payload[key]);
  } else {
      formData.append(key, '');
  }
  });
  return formData;
};
