import React from "react";
import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/AppFooter";

export default function Post1() {
  return (
    <div id="page" className="site">
      <AppHeader />

      <div id="content" className="site-content">
        <section className="section-blog">
          <div className="container">
            <div id="post1" className="blog-post_feature"></div>
          </div>
          <div className="container container-md">
            <div className="page-content">
              <h1>Trademark filings in Australia are up!</h1>
              <p>
                The latest Australian Intellectual Property Report shows that
                there has been 88,725 trademark applications filed in Australia
                for 2021. This is up 9% compared to 2020.
              </p>
              <p>
                For a copy of the full Report, please click{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.ipaustralia.gov.au/sites/default/files/ip_report-2022.pdf?_gl=1*12dlwub*_ga*MTgwMzkyNTg3My4xNjQ5ODA5ODUw*_ga_QX1TTWEZ1L*MTY1MTU0OTUwNi4xMy4xLjE2NTE1NDk1ODYuMA.."
                >
                  here
                </a>
                .
              </p>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
